import React from "react"
import { Link } from "gatsby"
import Div100vh from "react-div-100vh"

export default function Workspace() {
  return (
    <Div100vh id="entire">
      <header>
        <nav>
          <ul id="headerMenu">
            <li className="menuItem" id="name">
              <Link to="/">hannah s. kim</Link>
            </li>
            <li className="menuItem" id="about">
              <Link to="/about">about</Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className="hero">
        <div className="workspaceText">
          <p> Coming soon~ </p>
        </div>
      </div>
      <footer>
        <ul id="footerLinks">
          <li>
            <a
              href="mailto: hello@hannahs.kim"
              className="footerItem"
              id="email"
            >
              hello@hannahs.kim→
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/hannahs-kim"
              className="footerItem"
              id="linkedIn"
            >
              My LinkedIn→
            </a>
          </li>
        </ul>
      </footer>
    </Div100vh>
  )
}
